import request from '../request'

export function fetchList(params) {
    return request({
        url: '/market-service/bid/article/list',
        params
    })
}

export function fetchDetail(params) {
  return request({
      url: `/market-service/bid/article/query/${params}`
  })
}

export function add(data) {
  return request({
      url: '/market-service/bid/article/add',
      method: 'post',
      data
  })
}

export function edit(data) {
  return request({
      url: '/market-service/bid/article/update',
      method: 'post',
      data
  })
}

export function remove(data) {
  return request({
      url: '/market-service/bid/article/delete',
      method: 'post',
      data
  })
}
