<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-row>
        <a-col :span="22">
          <a-form-model
            :colon="false"
            :model="form"
            layout="inline"
            @keyup.enter.native="query"
          >
            <a-form-model-item>
              <a-input
                v-model="form.title"
                placeholder="标题"
                style="width: 150px"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-input
                v-model="form.author"
                placeholder="发布者"
                style="width: 150px"
              ></a-input>
            </a-form-model-item>
            <a-form-model-item>
              <a-radio-group v-model="form.category">
                <a-radio
                  v-for="item in typeList"
                  :key="item.value"
                  :value="item.value"
                  >{{ item.name }}</a-radio
                >
              </a-radio-group>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-button @click="$router.push($route.path + '/add')" type="primary"
              >发布文章</a-button
            >
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        rowKey="id"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="标题" data-index="title"> </a-table-column>
        <a-table-column title="发布者" data-index="author" align="center">
        </a-table-column>
        <a-table-column title="发布时间" data-index="publishAt" align="center">
        </a-table-column>
        <a-table-column title="创建方式" align="center">
          <template slot-scope="text">
            <span v-if="text.source === 'manual'">人工创建</span>
            <span v-if="text.source === 'auto'">系统生成</span>
          </template>
        </a-table-column>

        <a-table-column title="是否显示" align="center">
          <template slot-scope="text">
            <span v-if="text.isShow === 1" style="color: green">显示</span>
            <span v-else style="color: red">不显示</span>
          </template>
        </a-table-column>
        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a-space>
              <a
                href="#"
                @click.prevent="
                  $router.push($route.path + '/edit?id=' + text.id)
                "
                >编辑</a
              >
              <a href="#" class="danger" @click.prevent="deleteText(text)"
                >删除</a
              >
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { fetchList, remove } from "@/api/bidding/article";
import { mapGetters } from "vuex";

export default {
  mixins: [watermark],

  data() {
    return {
      form: {},
      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    typeList() {
      return this.findDataDict("bid_article_cate").filter(
        (item) => item.value !== "bid_win"
      );
    },
  },

  mounted() {
    this.form = {
      category: this.typeList[0].value,
    };
    this.getList();
  },

  methods: {
    handleTabChange(item) {
      this.form.category = item;
      this.getList();
    },

    getList() {
      const { current, pageSize, form } = this;

      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize: pageSize,
        category: "bid_news",
        ...form,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.current = 1;
      this.form = {};
      this.getList();
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除这篇文章吗？",
        onOk() {
          remove({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>